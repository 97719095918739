[data-amplify-authenticator] {
    --amplify-components-authenticator-container-width-max: 360px;
}

.amplify-button--primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  box-shadow: none;
}
